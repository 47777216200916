import React, {Suspense, useEffect, useState} from 'react';

import {useTranslation} from "react-i18next";
import {MotionAnimate} from 'react-motion-animate'
import {Link} from "react-router-dom";
import {DarkModeSwitch} from 'react-toggle-dark-mode';

function LinksPage() {

    const {t, i18n} = useTranslation();

    var _langClass = 'fw-bold';

    const _setLanguage = (lang) => {
        i18n.changeLanguage(lang)
        if (lang === 'ua') {
            document.getElementById('langUA').classList.add(_langClass);
            document.getElementById('langEN').classList.remove(_langClass);
        } else {
            document.getElementById('langUA').classList.remove(_langClass);
            document.getElementById('langEN').classList.add(_langClass);
        }
    }

    const _ua = <small id={'langUA'} onClick={() => _setLanguage('ua')}>UA</small>
    const _en = <small id={'langEN'} onClick={() => _setLanguage('en')}>EN</small>

    const [isDarkMode, setDarkMode] = React.useState(false);

    const toggleDarkMode = (checked) => {
        setDarkMode(checked);
    };

    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }, [isDarkMode]);

    return (
        <div id={'HomePage'} className={''}>

            <div id="section01" className={'vh-md-100 h-100'}>

                <div className="container-fluid row m-auto">
                    <div
                        className="col-md-6 d-none-d-md-block col-12 p-5 d-flex flex-column align-items-start justify-content-between vh-100">
                        <div className="text-start">
                            <div className="photo mb-5">
                                <img
                                     // src="https://v3.lukyanchuk.com/storage/files/1/img/me.jpg"
                                     src="https://avatars.githubusercontent.com/u/32492503?v=4"
                                     alt="my photo"
                                     className={'img-fluid rounded-circle shadow'}
                                     style={{
                                         width: "300px",
                                         // height: "300px",
                                         objectFit: "contain",
                                         position: 'right'
                                     }}
                                />

                            </div>
                            <h1>
                                <b>{t('my.name')} {t('my.surname')}</b>
                            </h1>
                            <h5 className={'lead text-secondary'}>
                                {t('my.job_position')} {t('my.stack')}
                            </h5>
                        </div>

                        <div className="settings text-start">
                            <DarkModeSwitch
                                style={{margin: '5px'}}
                                checked={isDarkMode}
                                onChange={toggleDarkMode}
                                moonColor={"grey"}
                                sunColor={"#FFBF00"}
                                size={25}
                            />

                            <> {_ua} | {_en} </>


                            <MotionAnimate animation='fadeInUp' reset={true}>
                                <small className="display-5">
                                    <a href="tel.:0980751357" className={'text-decoration-none text-secondary'}>
                                        +380 98 075 13 57
                                    </a>
                                </small>
                            </MotionAnimate>

                            <a href="https://links.lukyanchuk.com/" className={'text-decoration-none text-secondary'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="currentColor"
                                     className="bi bi-three-dots" viewBox="0 0 16 16">
                                    <path
                                        d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"/>
                                </svg>
                            </a>

                            <> </>

                            <a href="mailto:yarik@lukyanchuk.com" className={'text-secondary text-decoration-none'}>
                                <small>
                                    yarik@lukyanchuk.com
                                </small>
                            </a>
                        </div>
                    </div>
                    <div className="col-md-6 col-12 p-md-5 p-2 d-flex flex-column align-items-start justify-content-between vh-md-100 h-100">

                        <div className="social-blocks w-100">

                            <div className="row">
                                <div className="col-md-6 col-12">

                                    <div className="google-developer-card border rounded-5 p-4 shadow m-2 mt-4">

                                        <div className="d-flex">
                                            <div className={'col-md-6'}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor" className="bi bi-globe2" viewBox="0 0 16 16">
                                                    <path
                                                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855q-.215.403-.395.872c.705.157 1.472.257 2.282.287zM4.249 3.539q.214-.577.481-1.078a7 7 0 0 1 .597-.933A7 7 0 0 0 3.051 3.05q.544.277 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9 9 0 0 1-1.565-.667A6.96 6.96 0 0 0 1.018 7.5zm1.4-2.741a12.3 12.3 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332M8.5 5.09V7.5h2.99a12.3 12.3 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.6 13.6 0 0 1 7.5 10.91V8.5zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741zm-3.282 3.696q.18.469.395.872c.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a7 7 0 0 1-.598-.933 9 9 0 0 1-.481-1.079 8.4 8.4 0 0 0-1.198.49 7 7 0 0 0 2.276 1.522zm-1.383-2.964A13.4 13.4 0 0 1 3.508 8.5h-2.49a6.96 6.96 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667m6.728 2.964a7 7 0 0 0 2.275-1.521 8.4 8.4 0 0 0-1.197-.49 9 9 0 0 1-.481 1.078 7 7 0 0 1-.597.933M8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855q.216-.403.395-.872A12.6 12.6 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.96 6.96 0 0 0 14.982 8.5h-2.49a13.4 13.4 0 0 1-.437 3.008M14.982 7.5a6.96 6.96 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008zM11.27 2.461q.266.502.482 1.078a8.4 8.4 0 0 0 1.196-.49 7 7 0 0 0-2.275-1.52c.218.283.418.597.597.932m-.488 1.343a8 8 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z"/>
                                                </svg>

                                                <h6 className={'mt-3'}>Website</h6>
                                                <a href="https://lukyanchuk.com" className={'text-decoration-none'} target={'_blank'}>
                                                    <small className={'text-secondary'}>lukyanchuk.com</small>
                                                </a>
                                            </div>
                                            <div className="col-md-6">
                                                <img src="https://www.windmillstrategy.com/wp-content/uploads/What_is_a_website_redesign_vs_website_refresh.jpg" alt="website"
                                                     style={{
                                                         width: "100%",
                                                         height: "300px",
                                                         objectFit: "cover"
                                                     }}
                                                     className={'rounded-3'}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="google-developer-card border rounded-5 p-4 shadow m-2 mt-4">

                                        <div className="d-flex">
                                            <div className={'col-md-6'}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                     fill="currentColor" className="bi bi-phone" viewBox="0 0 16 16">
                                                    <path
                                                        d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                                                    <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                                                </svg>

                                                <h6 className={'mt-3'}>Apps</h6>
                                                <a href="https://apps.lukyanchuk.com" className={'text-decoration-none'} target={'_blank'}>
                                                    <small className={'text-secondary'}>apps.lukyanchuk.com</small>
                                                </a>
                                            </div>
                                            <div className="col-md-6">
                                                <img src="https://media.sketchfab.com/models/b3a6a573622b45cc9b11c9e1d34064f5/thumbnails/ae5ffaa2e43743c0a3476c35494b28a5/0c940ea11c8d4892908fd0424c4e0385.jpeg" alt="iphone"
                                                     style={{
                                                         width: "100%",
                                                         height: "110px",
                                                         objectFit: "cover"
                                                     }}
                                                     className={'rounded-3'}
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="d-flex">
                                        <div className="col-md-6 col-6 pt-4">

                                            <div className="blog-card border rounded-5 p-4 shadow m-2">
                                                <svg width="32" height="32" className="h-full w-full"
                                                     viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="32" height="32" rx="10" fill="white"></rect>
                                                    <path
                                                        d="M17.9792 12.2719C17.4566 12.1263 16.9114 12.4199 16.7615 12.9277C16.6117 13.4356 16.9139 13.9653 17.4365 14.1109L25.3857 16.3257C25.9084 16.4714 26.4536 16.1777 26.6034 15.6699C26.7533 15.162 26.4511 14.6323 25.9285 14.4867L17.9792 12.2719Z"
                                                        fill="black"></path>
                                                    <path
                                                        d="M15.7848 16.238C15.9346 15.7302 16.4798 15.4365 17.0025 15.5822L24.9517 17.797C25.4743 17.9426 25.7765 18.4723 25.6267 18.9802C25.4768 19.488 24.9316 19.7816 24.409 19.636L16.4597 17.4212C15.9371 17.2756 15.6349 16.7458 15.7848 16.238Z"
                                                        fill="black"></path>
                                                    <path
                                                        d="M16.0253 18.8924C15.5026 18.7468 14.9575 19.0404 14.8076 19.5483C14.6577 20.0561 14.9599 20.5859 15.4826 20.7315L20.4035 22.1025C20.9262 22.2482 21.4713 21.9545 21.6212 21.4467C21.7711 20.9389 21.4689 20.4091 20.9462 20.2635L16.0253 18.8924Z"
                                                        fill="black"></path>
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M13.4646 10.2181C13.9441 8.59302 15.6887 7.65339 17.3612 8.11937L28.7172 11.2834C30.3897 11.7494 31.3567 13.4445 30.8772 15.0696L26.5354 29.7819C26.0559 31.407 24.3113 32.3466 22.6388 31.8806L11.2828 28.7166C9.61031 28.2506 8.64328 26.5555 9.12285 24.9304L13.4646 10.2181ZM16.8185 9.95841L28.1745 13.1224C28.8017 13.2972 29.1643 13.9328 28.9845 14.5423L24.6428 29.2546C24.4629 29.864 23.8087 30.2163 23.1815 30.0416L11.8255 26.8776C11.1983 26.7028 10.8357 26.0672 11.0155 25.4578L15.3572 10.7454C15.5371 10.136 16.1913 9.78367 16.8185 9.95841Z"
                                                          fill="black"></path>
                                                </svg>

                                                <h6 className={'mt-3'}>Blog</h6>
                                                <a href="https://blog.lukyanchuk.com" className={'text-decoration-none'} target={'_blank'}>
                                                    <small className={'text-secondary'}>blog.lukyanchuk.com</small>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-6 pt-4">

                                            <div className="google-developer-card border rounded-5 p-4 shadow m-2">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                     fill="currentColor" className="bi bi-google" viewBox="0 0 16 16">
                                                    <path
                                                        d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z"/>
                                                </svg>

                                                <h6 className={'mt-3'}>Google Dev</h6>
                                                <a href="https://g.dev/lukyanchuk" className={'text-decoration-none'} target={'_blank'}>
                                                    <small className={'text-secondary'}>g.dev/lukyanchuk</small>
                                                </a>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6 col-12">

                                    <div className="d-flex flex-wrap">

                                        <div className="col-md-12 col-12">

                                            <div className="google-developer-card border rounded-5 p-4 shadow m-2 mt-4">

                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="logo-twitter rounded p-2 bg-info" style={{
                                                        width:'50px'
                                                    }}>

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                             fill="currentColor" className="bi bi-twitter text-white" viewBox="0 0 16 16">
                                                            <path
                                                                d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0 0 16 3.542a6.7 6.7 0 0 1-1.889.518 3.3 3.3 0 0 0 1.447-1.817 6.5 6.5 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.32 9.32 0 0 1-6.767-3.429 3.29 3.29 0 0 0 1.018 4.382A3.3 3.3 0 0 1 .64 6.575v.045a3.29 3.29 0 0 0 2.632 3.218 3.2 3.2 0 0 1-.865.115 3 3 0 0 1-.614-.057 3.28 3.28 0 0 0 3.067 2.277A6.6 6.6 0 0 1 .78 13.58a6 6 0 0 1-.78-.045A9.34 9.34 0 0 0 5.026 15"/>
                                                        </svg>

                                                    </div>

                                                    <a
                                                        href="https://twitter.com/yariklukyanchuk"
                                                        target={'_blank'}
                                                        className={'rounded-pill p-3 bg-info text-white text-decoration-none col-md-4 text-center'}
                                                    >
                                                        Follow
                                                    </a>
                                                </div>

                                                <div className="description">
                                                    <span className={''}>Twitter</span> <br/>
                                                    <small className={'text-secondary'}>@yariklukyanchuk</small>
                                                    <br/>
                                                    <small className="text-secondary">
                                                        Software Developer.
                                                    </small>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="col-md-6 col-6 pt-4">

                                            <div className="github-card border rounded-5 p-4 shadow m-2">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                     fill="currentColor" className="bi bi-github" viewBox="0 0 16 16">
                                                    <path
                                                        d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27s1.36.09 2 .27c1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.01 8.01 0 0 0 16 8c0-4.42-3.58-8-8-8"/>
                                                </svg>

                                                <h6 className={'mt-3'}>Github</h6>
                                                <a href="https://github.com/yarikthe" className={'text-decoration-none'} target={'_blank'}>
                                                    <small className={'text-secondary'}>yarikthe</small>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-6 pt-4">

                                            <div className="linkedin-card border rounded-5 p-4 shadow m-2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                     fill="currentColor" className="bi bi-linkedin text-primary" viewBox="0 0 16 16">
                                                    <path
                                                        d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                                                </svg>

                                                <h6 className={'mt-3'}>LinkedIn</h6>
                                                <a
                                                    href="https://www.linkedin.com/in/yaroslav-lukyanchuk-a028481ab"
                                                    target={'_blank'}
                                                    className={'rounded-3 p-2 bg-primary text-white text-decoration-none col-md-6 text-center'}
                                                >
                                                    <small>Get in touch</small>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-6 pt-2">

                                            <div className="github-card border rounded-5 p-4 shadow m-2">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                     fill="currentColor" className="bi bi-person-check"
                                                     viewBox="0 0 16 16">
                                                    <path
                                                        d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m1.679-4.493-1.335 2.226a.75.75 0 0 1-1.174.144l-.774-.773a.5.5 0 0 1 .708-.708l.547.548 1.17-1.951a.5.5 0 1 1 .858.514M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
                                                    <path
                                                        d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>
                                                </svg>

                                                <h6 className={'mt-3'}>About me</h6>
                                                <a href="/about" className={'text-decoration-none'} target={'_blank'}>
                                                    <small className={'text-secondary'}>read</small>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-6 pt-2">

                                            <div className="github-card border rounded-5 p-4 shadow m-2">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                     fill="currentColor" className="bi bi-mortarboard"
                                                     viewBox="0 0 16 16">
                                                    <path
                                                        d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917zM8 8.46 1.758 5.965 8 3.052l6.242 2.913z"/>
                                                    <path
                                                        d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466zm-.068 1.873.22-.748 3.496 1.311a.5.5 0 0 0 .352 0l3.496-1.311.22.748L8 12.46z"/>
                                                </svg>

                                                <h6 className={'mt-3'}>Science work</h6>
                                                <a href="science-work" className={'text-decoration-none'} target={'_blank'}>
                                                    <small className={'text-secondary'}>view</small>
                                                </a>
                                            </div>
                                        </div>

                                        <div className="google-developer-card border rounded-5 p-4 shadow m-2 mt-4 w-100">

                                            <div className="d-flex">
                                                <div className={'col-md-6'}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"
                                                         fill="currentColor" className="bi bi-book" viewBox="0 0 16 16">
                                                        <path
                                                            d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783"/>
                                                    </svg>

                                                    <h6 className={'mt-3'}>Books</h6>
                                                    <a href="https://books.lukyanchuk.com" className={'text-decoration-none'} target={'_blank'}>
                                                        <small className={'text-secondary'}>books.lukyanchuk.com</small>
                                                    </a>
                                                </div>
                                                <div className="col-md-6">
                                                    <img src="https://static.vecteezy.com/system/resources/previews/009/376/176/original/3d-black-book-illustration-png.png" alt={'book'}
                                                         style={{
                                                             width: "100%",
                                                             height: "100px",
                                                             objectFit: "contain"
                                                         }}
                                                         className={'rounded-3'}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>


            </div>

        </div>
    );
}

export default LinksPage;
