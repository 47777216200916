import React, {Suspense, useEffect, useState} from 'react';


import {useTranslation} from "react-i18next";
import {MotionAnimate} from 'react-motion-animate'
import {DarkModeSwitch} from 'react-toggle-dark-mode';

function HomePage() {

    const {t, i18n} = useTranslation();

    var _langClass = 'fw-bold';

    const _setLanguage = (lang) => {
        i18n.changeLanguage(lang)
        if (lang === 'ua') {
            document.getElementById('langUA').classList.add(_langClass);
            document.getElementById('langEN').classList.remove(_langClass);
        } else {
            document.getElementById('langUA').classList.remove(_langClass);
            document.getElementById('langEN').classList.add(_langClass);
        }
    }

    const _ua = <small id={'langUA'} onClick={() => _setLanguage('ua')}>UA</small>
    const _en = <small id={'langEN'} onClick={() => _setLanguage('en')}>EN</small>

    const [isDarkMode, setDarkMode] = React.useState(false);

    const toggleDarkMode = (checked) => {
        setDarkMode(checked);
    };

    useEffect(() => {
        if (isDarkMode) {
            document.body.classList.add('dark');
        } else {
            document.body.classList.remove('dark');
        }
    }, [isDarkMode]);

    return (
        <div id={'HomePage'} className={''}>

            <div id="section01" className={'vh-md-100 h-100'}>


                    <div
                        className="col-md-6 col-12 m-auto p-5 d-flex flex-column align-items-start justify-content-between vh-100">
                        <div className="text-start">
                            <div className="photo mb-5">
                                {/*<img src="https://v3.lukyanchuk.com/storage/files/1/img/me.jpg" alt="my photo"*/}
                                {/*     className={'img-fluid rounded-circle'}*/}
                                {/*     style={{*/}
                                {/*         width: "300px",*/}
                                {/*         height: "300px",*/}
                                {/*         objectFit: "cover",*/}
                                {/*         position: 'right'*/}
                                {/*     }}*/}
                                {/*/>*/}

                                <div style={{fontSize:'5rem'}}>🧑‍💻</div>

                            </div>
                            <h1>
                                <b>{t('my.name')} {t('my.surname')}</b>
                            </h1>
                            <h5 className={'lead text-secondary'}>
                                {t('my.job_position')} {t('my.stack')}
                            </h5>
                        </div>

                        <div className="settings text-start">
                            <DarkModeSwitch
                                style={{margin: '5px'}}
                                checked={isDarkMode}
                                onChange={toggleDarkMode}
                                moonColor={"grey"}
                                sunColor={"#FFBF00"}
                                size={25}
                            />

                            <> {_ua} | {_en} </>


                            <MotionAnimate animation='fadeInUp' reset={true}>
                                <small className="display-5">
                                    <a href="tel.:0980751357" className={'text-decoration-none text-secondary'}>
                                        +380 98 075 13 57
                                    </a>
                                </small>
                            </MotionAnimate>

                            <a href="/links" className={'text-decoration-none text-secondary'}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="currentColor"
                                     className="bi bi-three-dots" viewBox="0 0 16 16">
                                    <path
                                        d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3"/>
                                </svg>
                            </a>

                            <> </>

                            <a href="mailto:yarik@lukyanchuk.com" className={'text-secondary text-decoration-none'}>
                                <small>
                                    yarik@lukyanchuk.com
                                </small>
                            </a>
                        </div>
                    </div>

                </div>

        </div>
    );
}

export default HomePage;
